import React from "react";
import "./VacationCountTotals.scss";
import moment from "moment";
import "moment/locale/fr";

const VacationCountTotals = (props) => {
    const {
        beforeJune,
        rtt,
        cp,
        displayRemainderCounter,
        remainder,
        rttRemainder,
    } = props;

    const currentYear = moment().format("YYYY");

    return (
        <div className="vacation-totals">
            <div className="vacation-totals-header">
                Mon compteur d'absences
            </div>
            <div className="table">
                <div className="totals">
                    <div className="totals-label">Solde</div>
                    <div className="n-total">
                        <div className="n-total-label">CP</div>
                        <div className="n-total-amount">{cp}</div>
                    </div>
                    <div className="rtt-total">
                        <div className="rtt-total-label">RTT</div>
                        <div className="rtt-total-amount">{rtt}</div>
                    </div>
                </div>

                {rttRemainder > 0 &&
                    moment() >= moment(`${currentYear}-01-01`, "YYYY-MM-DD") &&
                    moment() < moment(`${currentYear}-01-04`, "YYYY-MM-DD") && (
                        <div className="rtt-remainder">
                            <div className="rtt-remainder-label">
                                dont RTT à poser avant le 31/03 :
                            </div>
                            <div className="rtt-remainder-amount">
                                {rttRemainder}
                            </div>
                        </div>
                    )}

                {beforeJune > 0 && (
                    <div className="before-june">
                        <div className="before-june-label">
                            dont CP à poser avant le 31/05 :
                        </div>
                        <div className="before-june-amount">{beforeJune}</div>
                    </div>
                )}

                {remainder > 0 && displayRemainderCounter && (
                    <div className="remainder">
                        <div className="remainder-label">
                            Solde CP Reliquat :{" "}
                        </div>
                        <div className="remainder-total">{remainder}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VacationCountTotals;
