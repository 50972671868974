import React, { useEffect, useState, Fragment } from "react";
import "./HomepageContainer.scss";
import { getContactDefaultRoute } from "config/Routes";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "moment/locale/fr";
import moment from "moment";
import Disclaimer from "components/common/Disclaimer";
import { changeOverlayContent } from "events/OverlayEvents";

import { EntityRepository } from "store/EntityRepository";
import Post from "entities/Post";

import * as NewsActions from "actions/News";
import * as UserActions from "actions/User";
import { setAppLoading } from "actions/Common";
import HomepagePost from "components/HomepageContainer/HomepagePost";
import Euro2020PopUp from "components/HomepageContainer/Euro2020PopUp";
import UnitedHeroesPopUp from "components/HomepageContainer/UnitedHeroesPopUp";
import CyberPopUp from "components/HomepageContainer/CyberPopUp/CyberPopUp";
import ScorpPopUp from "./ScorpPopUp/ScorpPopUp";
import {fetchInternalNews} from "actions/News";
import HomepageNews from "./HomepageNews";

const HomeApp = (props) => {
    const attributes = {};
    if (props.external) {
        attributes.target = "blank";
    }

    const styles = {};
    if (props.color) {
        styles.color = props.color;
    }
    const className = props.className
        ? props.className + " home-app"
        : "home-app";

    return props.external ? (
        <a href={props.url} className={className} target="_blank">
            <i style={styles} className={props.picto}></i>
            <div className="home-app-text">{props.text}</div>
            <div className="home-app-subtext">{props.subtext}</div>
        </a>
    ) : (
        <Link to={props.url} className={className}>
            <i style={styles} className={props.picto}></i>
            <div className="home-app-text">{props.text}</div>
            <div className="home-app-subtext">{props.subtext}</div>
        </Link>
    );
};

const HomeAppContainer = (props) => {
    return <div className="home-app-container">{props.children}</div>;
};

const HomepageContainer = () => {
    const dispatch = useDispatch();

    // const [popupVisible, setPopupVisible] = useState(
    //     !localStorage.getItem("scorpPopUpHidden")
    // );

    const me = useSelector((state) => state.user.me);
    const internalNews = useSelector((state) => state.news.entities.internalNews);

    const loadSpreadTracker = () => {
        window.domLoadEventFired = !0;
        let a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.charset = "UTF-8";
        a.id = "spread-tracker";
        a.src = "//static-sb.com/js/sb-tracker.js";
        document.body.appendChild(a);
    };

    useEffect(() => {
        if (!me.loaded) {
            dispatch(setAppLoading(true));
            dispatch(UserActions.fetchCurrentUser());
        }
        dispatch(NewsActions.fetchHighlight());
        dispatch(NewsActions.fetchInternalNews());
    }, []);

    useEffect(() => {
        // Méthode de chargement du tracker
        if (typeof me !== "undefined") {
            if (me.type === "consultant" || me.type === "internal") {
                loadSpreadTracker();
            }
        }

        // if (me.loaded && popupVisible && me.type !== "internal") {
        //     changeOverlayContent(
        //         <ScorpPopUp setPopupVisible={setPopupVisible}/>
        //     );
        // }
    }, [me]);

    // const openScorpPopup = () => {
    //     changeOverlayContent(<ScorpPopUp setPopupVisible={setPopupVisible} />);
    //     setPopupVisible(true);
    //     localStorage.removeItem("scorpPopUpHidden");
    // };

    return (
        me.loaded && (
            <div id="page-container" className="home-page">
                <div className="homepage-container">
                    {/*{me.type === "consultant" && (<div id="scorp-popup-link" onClick={openScorpPopup}>*/}
                    {/*    <div className="scorp-popup-link-title"><i className="fas fa-exclamation-circle"></i></div>*/}
                        {/*<div className="scorp-popup-link-content">Contribuez à la réalisation des offres de service Synchrone</div>*/}
                        {/*<div className="scorp-popup-link-about">*/}
                        {/*    En savoir + {" "}*/}
                        {/*    <span className="learn-more-arrow">*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="33" viewBox="0 0 46 33"><g><g><g><g><path fill="none" stroke="#17313a" stroke-linecap="square" stroke-miterlimit="20" d="M10.5 16h25"/></g><g><path fill="none" stroke="#17313a" stroke-miterlimit="20" d="M30.68 10v0l4.819 6v0l-4.819 6.335v0"/></g></g></g></g></svg>*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    {/*</div>)}*/}

                    <div className="container">
                        <div className="home-apps">
                            <div className="home-upper-container">
                                <HomepageNews internalNews={internalNews}/>
                                <HomepagePost />
                            </div>
                            <div className="home-lower-container">
                                <HomeApp
                                    color="#007188"
                                    url="/timesheet"
                                    text="Mes activités"
                                    subtext="CRA avant le 25 du mois"
                                    picto="fal fa-calendar-edit"
                                />
                                <HomeApp
                                    color="#a51160"
                                    url={getContactDefaultRoute(me)}
                                    text="Mes Contacts"
                                    picto="fal fa-user-friends"
                                />
                                {/* {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#00c1de"
                                        url="https://hcmcloudfr.talentiasw.com/hrsynch/landing-page/1105232"
                                        text="Mon Portail RH"
                                        picto="fal fa-comments-alt"
                                    />
                                )} */}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#8bc53f"
                                        url="https://www.expensya.com/Portal/#/Login?lang=fr"
                                        external="true"
                                        text="Mes Frais"
                                        subtext="Expensya"
                                        picto="fal fa-file-invoice-dollar"
                                    />
                                )}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#12afa9"
                                        url="/documents"
                                        text="Mes documents"
                                        picto="fal fa-folder-open"
                                    />
                                )}
                                {me.type !== "contractor" && (
                                    <HomeApp
                                        color="#12afa9"
                                        url="/documents/social"
                                        text="Mes informations sociales"
                                        picto="fal fa-flag"
                                    />
                                )}
                                <HomeApp
                                    color="#007188"
                                    url="/actus"
                                    text="Mes Actualités"
                                    picto="fal fa-newspaper"
                                />
                                <HomeApp
                                    color="#17313a"
                                    url="/tutorial"
                                    text="Mes Tutos"
                                    picto="fal fa-file"
                                />
                            </div>
                        </div>
                        <Disclaimer />
                    </div>
                </div>
            </div>
        )
    );
};

export default HomepageContainer;
