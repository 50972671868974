import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Disclaimer from "components/common/Disclaimer";
import { Form, Formik, Field, yupToFormErrors } from "formik";
import * as SecurityActions from "actions/Security";
import * as TimesheetActions from "actions/Timesheet";
import * as PasswordSchemas from "validation/PasswordSchema";

import "./ResetPasswordContainer.scss";

const ResetPasswordContainer = () => {
    const [isPasswordHidden, setPasswordHidden] = useState(true);
    const [isConfirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
    const dispatch = useDispatch();
    const [minimumCharactersValid, setMinimumCharactersValid] = useState(false);
    const [atLeastOneLowerValid, setAtLeastOneLowerValid] = useState(false);
    const [atLeastOneUpperValid, setAtLeastOneUpperValid] = useState(false);
    const [atLeastOneSpecialValid, setAtLeastOneSpecialValid] = useState(false);
    const [atLeastOneNumberValid, setAtLeastOneNumberValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const { token } = useParams();

    const ValidationChecks = () => {
        return (
            <div className="validation-checks">
                <div className="validation-check-header">
                    Votre mot de passe doit contenir :
                </div>
                <div
                    className={
                        "validation-check" +
                        (minimumCharactersValid ? " validated" : "")
                    }
                >
                    <i className="far fa-check-circle validation-icon"></i> Au
                    moins 12 caractères
                </div>
                <div className="validation-check-header">
                    Votre mot de passe ne doit pas contenir :
                </div>
                <div className={"validation-check"}>
                    3 lettres consécutives de votre nom ou prénom
                </div>
                <div className={"validation-check"}>
                    L'année en cours
                </div>
                <div className={"validation-check"}>
                    Le nom de l'entreprise
                </div>
                <div className="validation-check-header">
                    Et respecter les critères suivants :
                </div>
                <div
                    className={
                        "validation-check" +
                        (atLeastOneLowerValid ? " validated" : "")
                    }
                >
                    <i className="far fa-check-circle validation-icon"></i> Au
                    moins 1 minuscule
                </div>
                <div
                    className={
                        "validation-check" +
                        (atLeastOneUpperValid ? " validated" : "")
                    }
                >
                    <i className="far fa-check-circle validation-icon"></i> Au
                    moins 1 majuscule
                </div>
                <div
                    className={
                        "validation-check" +
                        (atLeastOneSpecialValid ? " validated" : "")
                    }
                >
                    <i className="far fa-check-circle validation-icon"></i> Au
                    moins 1 caractère spécial
                </div>
                <div
                    className={
                        "validation-check" +
                        (atLeastOneNumberValid ? " validated" : "")
                    }
                >
                    <i className="far fa-check-circle validation-icon"></i> Au
                    moins 1 chiffre
                </div>
            </div>
        );
    };

    return (
        <div id="login-container">
            <div id="form-forgot-password-container">
                <div className="forgot-password-header">
                    Changement de mot de passe
                </div>
                {/* {renderError()} */}
                <div className="forgot-password-splitter">
                    <Formik
                        // validateOnChange={false}
                        // validateOnBlur={false}
                        initialValues={{ password: "", confirmPassword: "" }}
                        validate={(values, props) => {
                            return Promise.all([
                                PasswordSchemas.minLengthSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setMinimumCharactersValid(true))
                                    .catch((err) => {
                                        setMinimumCharactersValid(false);
                                    }),
                                PasswordSchemas.atLeastOneLowerSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneLowerValid(true))
                                    .catch((err) => {
                                        setAtLeastOneLowerValid(false);
                                    }),
                                PasswordSchemas.atLeastOneUpperSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneUpperValid(true))
                                    .catch((err) => {
                                        setAtLeastOneUpperValid(false);
                                    }),
                                PasswordSchemas.atLeastOneSpecialSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneSpecialValid(true))
                                    .catch((err) => {
                                        setAtLeastOneSpecialValid(false);
                                    }),
                                PasswordSchemas.atLeastOneNumberSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setAtLeastOneNumberValid(true))
                                    .catch((err) => {
                                        setAtLeastOneNumberValid(false);
                                    }),
                                PasswordSchemas.passwordValidSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => setPasswordValid(true))
                                    .catch((err) => {
                                        setPasswordValid(false);
                                    }),
                                PasswordSchemas.confirmPasswordSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => {
                                        setConfirmPasswordValid(true);
                                    })
                                    .catch((err) => {
                                        setConfirmPasswordValid(false);
                                    }),
                            ]).then(() => {
                                return PasswordSchemas.completePasswordSchema
                                    .validate(values, {
                                        abortEarly: false,
                                    })
                                    .then(() => {
                                        return true;
                                    })
                                    .catch((err) => {
                                        return yupToFormErrors(err);
                                    });
                            });
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            let jsonData = {
                                password: {
                                    first: values.password,
                                    second: values.confirmPassword,
                                },
                            };
                            dispatch(
                                SecurityActions.resetPassword(
                                    token,
                                    JSON.stringify(jsonData)
                                )
                            ).then((response) => {
                                if (
                                    response.type ===
                                    SecurityActions.POST_RESET_SUCCESS
                                ) {
                                    let message =
                                        response.response.entities.message[
                                            response.response.result
                                        ].message;
                                    dispatch(
                                        TimesheetActions.notifySuccess(message)
                                    );
                                    setTimeout(() => {
                                        window.location.href = "/login";
                                    }, 5000);
                                }
                            });
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 1000);
                        }}
                    >
                        {({ errors, isSubmitting }) => (
                            <Form noValidate className="form-forgot-password">
                                <Field
                                    className={
                                        passwordValid ? "border-valid" : ""
                                    }
                                    id={"password"}
                                    // value={values.password}
                                    type={
                                        isPasswordHidden ? "password" : "text"
                                    }
                                    name="password"
                                    placeholder="Mot de passe"
                                />
                                <i
                                    id="reset-password-hidden-indicator"
                                    className={
                                        "far " +
                                        (isPasswordHidden
                                            ? "fa-eye"
                                            : "fa-eye-slash")
                                    }
                                    onClick={() =>
                                        setPasswordHidden(!isPasswordHidden)
                                    }
                                ></i>
                                {passwordValid && (
                                    <i className="password-valid far fa-check-circle"></i>
                                )}
                                <Field
                                    id="confirmPassword"
                                    // value={values.confirmPassword}
                                    type={
                                        isConfirmPasswordHidden
                                            ? "password"
                                            : "text"
                                    }
                                    name="confirmPassword"
                                    placeholder="Confirmation"
                                />

                                <i
                                    id="confirm-reset-password-hidden-indicator"
                                    className={
                                        "far " +
                                        (isConfirmPasswordHidden
                                            ? "fa-eye"
                                            : "fa-eye-slash")
                                    }
                                    onClick={() =>
                                        setConfirmPasswordHidden(
                                            !isConfirmPasswordHidden
                                        )
                                    }
                                ></i>
                                {confirmPasswordValid && (
                                    <i className="password-valid far fa-check-circle"></i>
                                )}
                                <button
                                    className={
                                        "next-btn" +
                                        (isSubmitting ||
                                        !passwordValid ||
                                        !confirmPasswordValid
                                            ? " disabled"
                                            : "")
                                    }
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        !passwordValid ||
                                        !confirmPasswordValid
                                    }
                                >
                                    Envoyer
                                </button>
                            </Form>
                        )}
                    </Formik>
                    <ValidationChecks />
                </div>
            </div>
            <Disclaimer reset={true}/>
        </div>
    );
};

export default ResetPasswordContainer;
