import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Formik, Field, yupToFormErrors } from "formik";
import Dropzone from "react-dropzone";
import File from "entities/File";
import { changeOverlayContent } from "events/OverlayEvents";
import Lightbox from "components/common/Lightbox";
import * as DocumentActions from "actions/Document";
import * as Yup from "yup";
import "./FileDepositLightbox.scss";

const FileDepositLightbox = (props) => {
    const { categoryId, folderId, unionId, type } = props;
    const [file, setFile] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [touched, setTouched] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    // const [formValues, setFormValues] = useState({ label: "" });
    const dispatch = useDispatch();

    const acceptedMimeTypes =
        "image/*, " +
        "text/csv, " +
        "text/plain, " +
        "application/pdf, " +
        "application/msword, " +
        "application/vnd.ms-excel, " +
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document, " +
        "application/vnd.openxmlformats-officedocument.presentationml.presentation, " +
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " +
        "application/vnd.oasis.opendocument.text, " +
        "application/vnd.oasis.opendocument.spreadsheet";

    const processFiles = (acceptedFiles, setValues) => {
        let tempFile = file;
        acceptedFiles.map((file) => {
            const reader = new FileReader();

            new Promise((resolve, reject) => {
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }).then((result) => {
                file.base64 = result.replace(/^data:.+;base64,/, "");
                tempFile = file;
                setFile(tempFile);
                setTouched(true);
                setValues({ label: file.path.replace(/\.[^/.]+$/, "") });
                // console.log(formValues);
            });
        });
    };

    const schema = Yup.object().shape({
        label: Yup.string().min(
            1,
            "Vous devez renseigner un nom pour votre document"
        ),
    });

    return (
        <Lightbox className="lb-w1200">
            <div className="lb-confirm-send">
                <div
                    className="lb-confirm-send-header"
                    style={{ textTransform: "uppercase" }}
                >
                    Déposer le document
                </div>
                <Formik
                    initialValues={{ label: "" }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validate={(values, props) => {
                        return schema
                            .validate(values, { abortEarly: false })
                            .then(() => {
                                return true;
                            })
                            .catch((err) => {
                                return yupToFormErrors(err);
                            });
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        const formData = new FormData();
                        formData.append("document", file);
                        dispatch(
                            categoryId
                                ? DocumentActions.uploadDocumentToCategory(
                                      categoryId,
                                      formData
                                  )
                                : folderId
                                ? DocumentActions.uploadDocumentToFolder(
                                      folderId,
                                      formData
                                  )
                                : DocumentActions.uploadUnionDocument(
                                      unionId,
                                      formData
                                  )
                        )
                            .then((response) => {
                                let newFile = new File(
                                    Object.values(
                                        response.response.entities.files
                                    )[0]
                                );
                                dispatch(
                                    DocumentActions.updateFileLabel(
                                        newFile.id,
                                        JSON.stringify(values),
                                        type
                                    )
                                );
                            })
                            .then(() => {
                                if (unionId) {
                                    dispatch(
                                        DocumentActions.fetchUnion(unionId)
                                    );
                                } else {
                                    dispatch(DocumentActions.fetchDocuments());
                                    dispatch(DocumentActions.fetchUnions());
                                }
                                changeOverlayContent(null);
                            });
                        setTimeout(() => {
                            setSubmitting(false);
                        }, 1000);
                    }}
                >
                    {({ handleChange, errors, isSubmitting, setValues }) => (
                        <Form className="lb-item-documents">
                            <div className="lb-item-row">
                                <label htmlFor="label">Nom du document</label>
                                <Field
                                    className={errors?.label ? "error" : ""}
                                    type="text"
                                    id="label"
                                    name="label"
                                    onChange={handleChange}
                                    // value={formValues.label}
                                />
                                {errors?.label && (
                                    <div className="error-warning">
                                        {errors.label}
                                    </div>
                                )}
                            </div>

                            <Dropzone
                                onDrop={(file) => processFiles(file, setValues)}
                                accept={acceptedMimeTypes}
                                // multiple={true}
                                maxSize={5242880}
                                disabled={disabled}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    acceptedFiles,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject,
                                }) => (
                                    <Fragment>
                                        <div
                                            {...getRootProps({
                                                className: "files-container",
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div
                                                className={
                                                    "dragzone" +
                                                    (disabled ? " hidden" : "")
                                                }
                                            >
                                                {file ? (
                                                    <div className="filename">
                                                        <i className="fal fa-file-download file-icon"></i>{" "}
                                                        {file.path}
                                                    </div>
                                                ) : (
                                                    "Sélectionner un fichier"
                                                )}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </Dropzone>
                            <div className="lb-confirm-send-actions">
                                <div
                                    onClick={() => changeOverlayContent(null)}
                                    className="lb-confirm-send-cancel"
                                >
                                    Annuler
                                </div>
                                <button
                                    type="submit"
                                    // onClick={handleSubmit}
                                    className={
                                        "lb-confirm-send-confirm" +
                                        (submitting || !touched
                                            ? " disabled-btn"
                                            : "")
                                    }
                                >
                                    Envoyer
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Lightbox>
    );
};

export default FileDepositLightbox;
